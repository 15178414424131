import { graphql } from "gatsby"
import React, { Component } from "react"
import styled from 'styled-components';
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from "../components/default-hero"
import { FaqGroup } from '../components/faq-accordions'
import SidebarForm from '../components/sidebar-form';

import YoastSeoParts from '../fragments/seo';

import '../css/faq.css';

class FaqTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo, template: { templateFAQ: { taxonomies } } } = page

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <DefaultHeroWrapper>
          <DefaultHero title={page.title} backgroundColor="#005c85" />
        </DefaultHeroWrapper>

        <div className="container baseContainerWrapper">
          <div className="baseContainer">
            <div className="grid smallGrid">
              <div className="col-md-8 baseContainerLeft">
              { taxonomies &&
                taxonomies.map((tax, index) => {
                  return (
                    <React.Fragment>
                      <FaqGroup key={tax.id} title={tax.name} group={tax.contentNodes.nodes} classes='col-md-12' />
                    </React.Fragment>
                  )
                })
              }
                {/* <FaqListing groups={taxonomies} /> */}
              </div>
              <div className="col-md-4 baseContainerRight faq-sidebar">
                <SidebarForm />
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

const DefaultHeroWrapper = styled.div`
  .dots span {
    color: #fe6a00;
  }

  .defaultHero {
    border-top-color: #fe6a00;
  }
`

export default FaqTemplate

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_FAQ {
          templateName
          templateFAQ {
            taxonomies {
              id
              name
              contentNodes {
                nodes {
                  ... on WpFaq {
                    id
                    title
                    link
                    excerpt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`